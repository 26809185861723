import { gql } from 'graphql-tag'


export const GET_NOTIFICATIONS = gql`
subscription MyQuery {
  notifications(order_by: [{ read: asc }, { created_at: desc }]) {
    comment
    created_at
    icon
    id
    link_access
    read
    user {
      email
      displayName
    }
  }
}

`;

export const UPDATE_READ_NOTIFICATION = gql`
mutation MyMutation($id: Int!) {
  update_notifications_by_pk(pk_columns: {id: $id}, _set: {read: true}) {
    id
  }
}
`;

export const UPDATE_ALL_READ = gql`
mutation MyMutation($tenant: uuid!, $user_id: uuid!) {
  update_notifications(
    where: {
      user_id: { _eq: $user_id },
      _or: [
        { tenant_id: { _eq: $tenant } },
        { tenant_id: { _is_null: true } }
      ]
    },
    _set: { read: true }
  ) {
    affected_rows
  }
}

`