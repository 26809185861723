import { useSubscription, useMutation } from "@vue/apollo-composable";
import { provideApolloClient } from "@vue/apollo-composable";
import { GET_NOTIFICATIONS, UPDATE_READ_NOTIFICATION, UPDATE_ALL_READ } from '../queries/notificationsQ';
//@ts-ignore
import { apolloClient } from "@/main";
//@ts-ignore
import { nhostAuth } from "@baymetrics/trustech";



export const getNotifications = async () => {
    const { result, loading, error } = await provideApolloClient(apolloClient)(() =>
      useSubscription(GET_NOTIFICATIONS)
      );
      return { result, loading, error };
}

export const updateReadNotification = async (id: number) => {
    try{
      const { mutate: mutateInsert } = provideApolloClient(apolloClient)(() =>
        useMutation(UPDATE_READ_NOTIFICATION)
      );
      const response = await mutateInsert({
        id,
      });
      return response?.data.update_notifications_by_pk?.id;
    }catch(e){
        console.log(e);
    }
}


export const updateAllReadNotification = async () => {
  try{
    const user_id = nhostAuth.auth.getUser().id
    const tenant = nhostAuth.auth.getHasuraClaim("x-hasura-Tenant-Now-Id")
    const { mutate: mutateInsert } = provideApolloClient(apolloClient)(() =>
      useMutation(UPDATE_ALL_READ)
    );
    const response = await mutateInsert({
      user_id,
      tenant
    });
    return response?.data.update_notifications?.affected_rows;
  }catch(e){
      console.log(e);
  }
}

